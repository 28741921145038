'use client';

import React from 'react';
import { styled } from '@linaria/react';
import { ArrowUpLeft } from 'react-feather';

import { DARK_COLORS } from '@/constants';
import useMousePosition from '@/hooks/use-mouse-position';

import MaxWidthWrapper from '@/components/MaxWidthWrapper';
import Spacer from '@/components/Spacer';
import Link from '@/components/Link';
import Me from '@/components/JericaMascot';

const NotFoundPage = () => {
  const [allowEffect, setAllowEffect] = React.useState(true);
  const { x, y } = useMousePosition();

  React.useEffect(() => {
    if (typeof x === 'number' && typeof y === 'number') {
      document.documentElement.style.setProperty(
        '--cursorX',
        x + 'px'
      );
      document.documentElement.style.setProperty(
        '--cursorY',
        y + 'px'
      );
    }
  }, [x, y]);

  return (
    <OuterWrapper>
      <ToggleButton onClick={() => setAllowEffect(false)}>
        Disable flashlight effect
      </ToggleButton>
      <Wrapper>
        <Title>Not Found</Title>
        <Description>
          Sorry, there is nothing at this URL.
        </Description>
        <BackLink href="/">
          <ArrowUpLeft size="2rem" />
          <Spacer size={8} />
          Go back home
        </BackLink>

        <MeWrapper>
          <Me colorModeOverride="dark" />
        </MeWrapper>
      </Wrapper>
      {allowEffect && <Effect />}
    </OuterWrapper>
  );
};

function Effect() {
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
@media (hover: hover) {
  body {
    cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACQSURBVHgBhZA9CsQgEIVnxCJHSJkjpFGw8wh7xD2KtRbmCClzhAVRdx6YLsQHyoifP/MxjeSct1KKl3Jl5kXG2Vo7rLUH9hlTSgmAp+cEY0zgGOMupz/0Eq31V0l2mqTW6lXvfaN5VoC/GSXMgqevGQgDSpSEGQhNyjl3Sv0GB7jkewVNMIDmxr8v6TaMi+gPLrNAA0C8lG8AAAAASUVORK5CYII='),
      auto;
  }

  :root {
    cursor: none;
    --cursorX: 0px;
    --cursorY: 0px;
  }

  :root:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    pointer-events: none;
    background: radial-gradient(
      circle 16vmax at var(--cursorX) var(--cursorY),
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 80%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
}

        `,
      }}
    />
  );
}

const OuterWrapper = styled.div`
  min-height: 100vh;
  min-height: 100svh;
  background: ${DARK_COLORS['--color-background']};
  color: ${DARK_COLORS['--color-text']};
`;

const ToggleButton = styled.button`
  position: fixed;
  z-index: 99999;
  top: 32px;
  left: 32px;
  padding: 16px 24px;
  border-radius: 12px;
  background: white;
  color: black;
  box-shadow:
    0 -5.9px 2.7px rgba(0, 0, 0, 0.018),
    0 -1.2px 6.9px rgba(0, 0, 0, 0.024),
    0 8px 14.2px rgba(0, 0, 0, 0.03),
    0 21.9px 29.2px rgba(0, 0, 0, 0.039),
    0 49px 80px rgba(0, 0, 0, 0.07);

  /* Visually hidden stuff */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;

  &:focus {
    width: auto;
    height: auto;
    clip: auto;
  }
`;

const Wrapper = styled(MaxWidthWrapper)`
  position: relative;
  max-width: 800px;
  padding-top: 18vh;
  animation: fadeIn 750ms 200ms ease-in both;
`;

const Title = styled.h1`
  font-size: 12vw;
  font-weight: var(--font-weight-bold);

  @media (max-width: 550px) {
    font-size: 64px;
  }
  @media (min-width: 1200px) {
    font-size: 144px;
  }
`;

const Description = styled.p`
  margin-top: 0px;
  font-size: 4vw;
  color: ${DARK_COLORS['--color-text']};

  @media (max-width: 550px) {
    font-size: 32px;
  }
  @media (min-width: 1200px) {
    font-size: 48px;
  }
`;

const BackLink = styled(Link)`
  display: inline-flex;
  align-items: baseline;
  text-decoration: none;
  font-size: 2.25rem;
  margin-top: 96px;
  color: ${DARK_COLORS['--color-tertiary']};

  @media (max-width: 550px) {
    font-size: 24px;
  }
  @media (min-width: 1200px) {
    font-size: 36px;
  }
`;

const MeWrapper = styled.div`
  position: absolute;
  top: 32vh;
  right: -100px;
  display: none;

  @media (min-width: 1000px) {
    display: block;
  }
`;

export default NotFoundPage;
